<template>
  <div>
    <head-layout  :head-title="$t('cip.dc.transformation.title.name')"  :head-btn-options="headBtnOptions" @head-add="addFn" @head-romve="deleteFn">
    </head-layout>
    <el-container class="new-sino-box" >
      <el-header class="new-sino-header search-form" style="height:56px">
        <div style="display: flex;align-items: center;height: 100%" >
          <el-input :placeholder="$t('cip.dc.transformation.msg.placeholder')" v-model="queryList.param1" style="width: 200px" size="mini" />

          <el-button-group style="margin-left: 10px;">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad2"></el-button>
          <el-button size="mini"  icon="reset-refresh icon-refresh" @click="czFn"></el-button>
          </el-button-group>
        </div>
      </el-header>

      <el-main class="new-sino-main">

        <grid-layout
          ref="gridLayout"
          :data-total="total"
          @page-size-change="handleSizeChange"
          @page-current-change="handleCurrentChange"
          @page-refresh-change="onLoad"
          :page="page"
          @gird-handle-select-click="handleSelectionChange"
          :tableOptions="tableOptions"
          :tableData="tableData"
          :tableLoading="tableLoading"
          :gridRowBtn="gridRowBtn"
          @grid-edit="handleEdit"
          @grid-del="deleteFn2"
        >
        </grid-layout>
<!--        <el-table-->
<!--          v-tableHe-->
<!--          size="mini"-->
<!--          :element-loading-text="$t('transformation.LoadingDesperately')"-->
<!--          element-loading-spinner="el-icon-loading"-->
<!--          element-loading-background="rgba(0, 0, 0, 0.8)"-->
<!--          border-->
<!--          :data="tableData"-->
<!--          @selection-change="handleSelectionChange"-->
<!--          style="width: 100%">-->
<!--          <el-table-column-->
<!--            type="selection"-->
<!--            width="55">-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="infCode"-->
<!--            :label="$t('cip.dc.transformation.field.infCode')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="fieldName"-->
<!--            :label="$t('cip.dc.transformation.field.fieldName')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="targetTable"-->
<!--            :label="$t('cip.dc.transformation.field.targetTable')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="srcField"-->
<!--            :label="$t('cip.dc.transformation.field.srcField')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="targetField"-->
<!--            :label="$t('cip.dc.transformation.field.targetField')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="unitCode"-->
<!--            :label="$t('cip.dc.transformation.field.unitCode')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="mapCode"-->
<!--            :label="$t('cip.dc.transformation.field.mapCode')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="groupNo"-->
<!--            :label="$t('cip.dc.transformation.field.groupNo')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="defaultValue"-->
<!--            :label="$t('cip.dc.transformation.field.defaultValue')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="dimension"-->
<!--            :label="$t('cip.dc.transformation.field.dimension')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="dimensionSed"-->
<!--            :label="$t('cip.dc.transformation.field.dimensionSed')"-->
<!--          >-->
<!--          </el-table-column>-->
<!--          <el-table-column-->
<!--            align="center"-->
<!--            prop="address"-->
<!--            label="操作">-->
<!--            <template slot-scope="scope">-->
<!--              <el-button-->
<!--                size="mini"-->
<!--                type="text"-->
<!--                @click="handleEdit(scope.$index, scope.row)">{{$t('cip.dc.transformation.field.edit')}}</el-button>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table>-->
<!--        <div style="float:right;height: 60px;">-->
<!--          <el-pagination-->
<!--            @size-change="handleSizeChange"-->
<!--            @current-change="handleCurrentChange"-->
<!--            :current-page.sync="queryList.query.current"-->
<!--            :page-size="queryList.query.size"-->
<!--            layout="total, sizes, prev, pager, next, jumper"-->
<!--            :total="total">-->
<!--          </el-pagination>-->
<!--        </div>-->
      </el-main>
    </el-container>

  </div>
</template>

<script>
import {
  itfTransList,
  itfTransUp,
  itfTransDel,
  dataItfMapSel,
  dataItfUnitSel, dataSplitSel, dataSplitDel
} from "@/api/dataAcquisition/index";
import { mapGetters } from "vuex";
import website from '@/config/website';
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      multipleSelection:[],
      tableLoading:false,
      page: {
        pageSize: PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        currentPage: 1,
        total: 0,
        selectVal:''
      },
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 100,
        column: [
          {
            label: this.$t('cip.dc.transformation.field.infCode'),
            prop: "infCode",
            cell: true,
          },
          {
            label: this.$t('cip.dc.transformation.field.fieldName'),
            prop: "fieldName",
            cell: true,
          },
          {
            label: this.$t('cip.dc.transformation.field.targetTable'),
            prop: "targetTable",
            cell: true,
          },
          {
            label: this.$t('cip.dc.transformation.field.srcField'),
            prop: "srcField",
            cell: true,
          },
          {
            label: this.$t('cip.dc.transformation.field.targetField'),
            prop: "targetField",
            cell: true,
          },
          {
            label: this.$t('cip.dc.transformation.field.unitCode'),
            prop: "unitCode",
            slot:true,
            cell: true,

          },
          {
            label: this.$t('cip.dc.transformation.field.mapCode'),
            prop: "mapCode",
            slot:true,
            cell: true,

          },
          {
            label: this.$t('cip.dc.transformation.field.groupNo'),
            prop: "groupNo",
            slot:true,
            cell: true,

          },
          {
            label: this.$t('cip.dc.transformation.field.defaultValue'),
            prop: "defaultValue",
            slot:true,
            cell: true,

          },
          {
            label: this.$t('cip.dc.transformation.field.dimension'),
            prop: "dimension",
            slot:true,
            cell: true,
          },
          {
            label: this.$t('cip.dc.transformation.field.dimensionSed'),
            prop: "dimensionSed",
            slot:true,
            cell: true,
          },
        ],
      },
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          type: "text",
          icon: ""
        },
        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-del",
          type: "text",
          icon: ""
        },

      ],
      tableData: [],
      total:0,
      queryList:{
        query:{
          current:1,
          size:PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        },
        selectVal: "",
      },
      formLabelWidth: '120px',
      dialogFormVisible:false,
      loading:false,
      headBtnOptions: [{
        label: this.$t("cip.cmn.btn.addBtn"),
        emit: "head-add",
        type: "button",
        icon: ""
      }, {
        label: this.$t("cip.cmn.btn.delBtn"),
        emit: "head-romve",
        type: "button",
        icon: ""
      }],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false)
      };
    },
  },
  methods: {
    init(){
      console.log(this.queryList,"rrrrrrrr");
      this.onLoad()
    },

    onLoad(page, params = {}) {
      this.loading = true;
      this.queryList.selectVal = this.page.selectVal;
      this.queryList.query.current = this.page.currentPage;
      this.queryList.query.size = this.page.pageSize;
      itfTransList(this.queryList).then(res => {
        this.loading = false;
        let obj = res.data.data;
        this.tableData = obj.records
        this.total = obj.total;
        this.page.total=obj.total;
      });
    },
    addFn(){
      this.$router.push({
        path: '/dataHandle/transformationAdd',
        query: {
          type: 'add',
        }
      })
    },
    handleEdit(item){
      this.$router.push({
        path: '/dataHandle/transformationAdd',
        query: {
          type: 'edit',
          id:item.id
        }
      })
      sessionStorage.setItem('transformEditObj',JSON.stringify(item))
    },
    deleteFn(){
      if(this.multipleSelection.length == 0){
        this.$message.error('请选择删除数据')
        return
      }
      let ids = this.multipleSelection.map((item)=>{
        return item.id
      })
      this.$confirm(this.$t('transformation.fun.isDelect'), this.$t('transformation.tip'), {
        confirmButtonText: this.$t('transformation.confirmButtonText'),
        cancelButtonText: this.$t('transformation.cancelButtonText'),
        type: 'warning'
      }).then(() => {
        itfTransDel({ids}).then((res)=>{
          this.$message.success(this.$t('transformation.fun.DeleteSucceeded'));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    deleteFn2(e){
      let ids = [e.id]
      this.$confirm(this.$t('transformation.fun.isDelect'), this.$t('transformation.tip'), {
        confirmButtonText: this.$t('transformation.confirmButtonText'),
        cancelButtonText: this.$t('transformation.cancelButtonText'),
        type: 'warning'
      }).then(() => {
        itfTransDel({ids}).then((res)=>{
          this.$message.success(this.$t('transformation.fun.DeleteSucceeded'));
          this.onLoad()
        })
      }).catch(() => {

      });

    },
    czFn(){
      this.queryList = {
        query:{
          current:1,
          size:PAGE_CONSTANT.DEFAULT_PAGE_SIZE,
        },
        param1: "",
      }

      this.onLoad()
    },
    onLoad2(){

      this.queryList.query.current = 1
      this.page.currentPage = 1
      this.onLoad()
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val;
    },
    handleSizeChange(val) {
      this.queryList.query.size  = val.pageSize;
      this.page.pageSize= val.pageSize
      this.page.currentPage = 1
      this.queryList.query.page = 1
      this.onLoad()
    },
    handleCurrentChange(val) {
      this.page.currentPage = val.currentPage
      this.queryList.query.page = val.currentPage;
      this.onLoad()
    },
  },
  created() {
    this.init()

  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.search-form::v-deep{
   background: #FFFFFF;
   padding: 14px 12px;
  .el-form-item--small .el-form-item__content{
    line-height: 28px !important;
  }
  .el-button{
    height: 28px;
    margin-left:0;
   }
  .el-input__inner{
   height: 28px;
   line-height: 28px;
  }
}
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
</style>
