<template>
    <div>
      <head-layout head-title="应用列表" :head-btn-options="headBtnOptions" @head-add="toadd()">
      </head-layout>
      <div class="headers" style="display: flex">
        <el-input
          placeholder="应用名称"
          v-model="listParms.appName"
          class="hinput"
          size="mini"
          style="width: 200px"
        />
        <el-button-group style="margin-left: 10px">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="onLoad"
          ></el-button>
          <el-button
            size="mini"
            icon="reset-refresh icon-refresh"
            @click="czFn"
          ></el-button>
        </el-button-group>
      </div>

      <grid-layout
        ref="gridLayout"
        :data-total="total"
        @page-size-change="handleSizeChange"
        @page-current-change="handleCurrentChange"
        @page-refresh-change="onLoad"
        :page="page"
        @gird-handle-select-click="handleSelectionChange"
        :tableOptions="tableOptions"
        :tableData="tableData"
        :tableLoading="tableLoading"
        :gridRowBtn="gridRowBtn"
        @grid-edit="toSee"
        @grid-romve ="toDel"
        :searchColumns="searchColumns"
      >

      </grid-layout>

      <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <span>{{dialogtext}}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="SureToExecute">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>

  <script>
  import { ZBList, ExecuteTask,DelCheckTask,DelZBFX } from "@/api/dataAcquisition/index";
  import HeadLayout from "@/views/components/layout/head-layout";
  import GridLayout from "@/views/components/layout/grid-layout";
  import { PAGE_CONSTANT } from "@/util/pageConstantEnum";

  export default {
    components: {
      HeadLayout,
      GridLayout,
    },
    props: {},
    data() {
      return {
        total:'',
        dialogtext:'',
        btnType:'',
        page: {
          pageSize: PAGE_CONSTANT.TEN,
          currentPage: 1,
          total: 0
        },
        dialogVisible: false,
        tableLoading: false,
        ExecuteId: "",
        tableData: [],
        listParms: {
          pageNum: 1,
          pageSize: 10,
          appName: "",
        },
        gridRowBtn: [
          {
            label: this.$t("cip.cmn.btn.editBtn"),
            emit: "grid-edit",
            remark: "edit",
            type: "text",
            icon: "",
          },

        //   {
        //     label: this.$t("cip.cmn.btn.viewBtn"),
        //     emit: "grid-view",
        //     remark: "view",
        //     type: "text",
        //     icon: "",
        //   },

          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "grid-romve",
            remark: 'remove',
            type: "text",
            icon: ""
          },
        ],

        headBtnOptions: [
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add",
            type: "button",
            icon: "",
          },
        ],

        tableOptions: {
          customRowAddBtn: false,
          menuWidth: 200,
          column: [
            {
              label: "应用名称",
              prop: "appName",
              cell: true,
            },
            {
              label: "应用编号",
              prop: "appNo",
              cell: true,
            },
            // {
            //   label: "合格基线",
            //   prop: "red",
            //   cell: true,
            // },
            // {
            //   label: "优秀基线",
            //   prop: "green",
            //   cell: true,
            // },

            {
              label: "创建人",
              prop: "createUser",
              cell: true,
            },
            {
              label: "创建时间",
              prop: "createTime",
              cell: true,
            },
          ],
        },
      };
    },

    methods: {
      //获取列表
      getList(parms) {
        ZBList(parms).then((res) => {
          this.tableData = res.data.data.dataList;
          this.total = res.data.data.page.totalSize
        });
      },
      onLoad(){
        this.getList(this.listParms)
      },
      czFn(){
        this.listParms.pageNum = 1
        this.listParms.pageSize = 10
        this.listParms.appName = ""
        this.getList(this.listParms)
      },
      //size改变
      handleSizeChange(e) {
        this.page.pageSize = e.pageSize
        this.listParms.pageSize = e.pageSize
        this.getList(this.listParms)
      },
      //页数改变
      handleCurrentChange(e) {
        this.page.currentPage = e.currentPage
        this.listParms.pageNum = e.currentPage
        this.getList(this.listParms)
      },




      //查看
      toSee(item) {
        this.$router.push({
          path: "/datacenter/market/ApplicationList/detail",
          query: {
            type: "see",
            id: item.id,
          },
        });
      },

      toadd(){
        this.$router.push({
          path: "/datacenter/market/ApplicationList/detail",
          query: {
            type: "add",
          },
        });
      },
      //执行和删除


      SureToExecute() {
        this.dialogVisible = false;

        if(this.btnType == 'del'){
            DelZBFX({id:this.ExecuteId}).then(res=>{
            this.$message({
            message: "删除成功！",
            type: "success",
          });
          this.getList(this.listParms)
           })
        }

      },
      toDel(item){
        this.dialogVisible = true;
        this.dialogtext = '是否删除此任务？'
        this.ExecuteId = item.id;
         this.btnType = 'del'

      }
    },
    mounted() {
      this.getList(this.listParms);
    },
  };
  </script>
  <style  scoped>
  .headers {
    background: #ffffff;
    padding: 14px 12px;
  }

  .headers ::v-deep .el-button {
    height: 28px;
  }
  </style>
